/* Loading progress bar styles (start) */
.playProgressAnimation {
    animation: progress-animation 2s forwards;
}

@keyframes progress-animation {
    0% {
        width: 0%;
    }

    40% {
        width: 25%;
    }

    100% {
        width: 100%;
    }
}

/* Loading progress bar styles (end) */