@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: 'Montserrat', sans-serif;
}

.pending {
    color: red;
}

.approved {
    color: green;
}