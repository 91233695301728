.opacity_0 {
    opacity: 0;
}
/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------ */

.bottom2top_2s {
    animation: 2s anim-lineUp ease-out;
}

.bottom2top_3s {
    animation: 3s anim-lineUp ease-out;
}

/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------ */


/* Animations (start) */

/* Description: Fade In from Bottom to Top */
@keyframes anim-lineUp {
    0% {
        opacity: 0;
        transform: translateY(80%);
    }

    20% {
        opacity: 0;
    }

    50% {
        opacity: 1;
        transform: translateY(0%);
    }

    100% {
        opacity: 1;
        transform: translateY(0%);
    }
}

/* Animations (end) */