.animate_top_companies {
    animation: 2s anim-lineUp ease-out;
}

.opacity_0 {
    opacity: 0;
}

/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------ */

.bottom2top_2s {
   animation: 2s anim-lineUp ease-out;
}

.bottom2top_3s {
    animation: 3s anim-lineUp ease-out;
}

.bottom2top_4s {
    animation: 4s anim-lineUp ease-out;
}

/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------ */

.left2right_2s {
    animation: 2s fade-in-leftToRight ease-out;
}

.left2right_2_5s {
    animation: 2.5s fade-in-leftToRight ease-out;
}

.left2right_3s {
    animation: 3s fade-in-leftToRight ease-out;
}

/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------ */

.right2left_2s {
    animation: 2s fade-in-RightToLeft ease-out;
}

.right2left_2_5s {
    animation: 2.5s fade-in-RightToLeft ease-out;
}

.right2left_3s {
    animation: 3s fade-in-RightToLeft ease-out;
}

/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------ */


/* Animations (start) */

/* Description: Fade In from Bottom to Top */
@keyframes anim-lineUp {
    0% {
        opacity: 0;
        transform: translateY(80%);
    }

    20% {
        opacity: 0;
    }

    50% {
        opacity: 1;
        transform: translateY(0%);
    }

    100% {
        opacity: 1;
        transform: translateY(0%);
    }
}

/* Description: Fade In from Left to Right */
@keyframes fade-in-leftToRight {
    0% {
        opacity: 0;
        transform: translateX(-80%);
    }

    20% {
        opacity: 0;
    }

    50% {
        opacity: 1;
        transform: translateX(0%);
    }

    100% {
        opacity: 1;
        transform: translateX(0%);
    }
}

/* Description: Fade In from Left to Right */
@keyframes fade-in-RightToLeft {
    0% {
        opacity: 0;
        transform: translateX(180%);
    }

    20% {
        opacity: 0;
    }

    50% {
        opacity: 1;
        transform: translateX(0%);
    }

    100% {
        opacity: 1;
        transform: translateX(0%);
    }
}

/* Description: Fade In Only */
@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

/* Animations (end) */